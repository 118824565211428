import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h2>Standard Shipping</h2>
                                <h3>Returns and Exchange</h3>

<p>Product(s) can be exchanged from any of our retail stores within 5 working days from the date of delivery. Please note that the product must be unused, all tags must be intact, packaging must be in its original condition, and the original invoice is required. Items bought on sales and deals cannot be exchanged. We will only exchange or refund any product(s) if it is defective or the wrong product(s) has been delivered.</p>

<p>For online orders, contact our customer service center by emailing us images of the defective/wrong product at <a href="mailto:kmelectronics.com.pk@gmail.com">kmelectronics.com.pk@gmail.com</a> or call us at +92(021)32722324 within 2 days of delivery. After our team reviews the product, we will exchange your product. For products bought from our stores, refer to our in-store return policy.</p>

<br/>

<h3>Refunds</h3>

<p>We have a No Refund Policy. However, refunds may be allowed in exceptional cases wherein the product is out of stock. You will be credited for the cost of the item(s) and applicable taxes. Shipping and handling costs are non-refundable. Please allow 7 days for the processing of returns and up to 2 working days for payment return.</p>

<br/>

<h3>Order Cancellation</h3>

<p>You may cancel your order at any time before the order is processed. Once a product is shipped, the 'Return and Exchange Policy' will apply. KM Electronics may cancel orders for any reason. Common reasons may include: the item is out of stock, pricing errors, or credit card payment is declined by the issuing financial institution.</p>
<br/>

<h3>Shipment Policy:</h3>

<p>KM Electronics will call the customer to confirm the order. Orders will be processed after confirmation from the customer. For Cash on Delivery (COD), please pay the driver or rider after opening the package. For queries, contact the Customer Service Centre at email <a href="mailto:kmelectronics.com.pk@gmail.com">kmelectronics.com.pk@gmail.com</a> or call us at +92(021)32722324, 0331-8901247.</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;