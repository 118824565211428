import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
// import ch344444 from '../assets/images/dollar-bill.png';
// import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import Toyo51 from '../assets/images/bannerFortuner.jpeg';
import { Carousel } from 'react-responsive-carousel';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



          <div class="video-banner bg-light pt-5 pb-5">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
		                				<h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Pak Dream Rent a Car</strong></h3>
	                					<p>Pak Dream Rent a Car is an on-demand, car-rental marketplace geared to disrupt the traditional renting industry. Pak Dream Rent a Car car-rental services are available at your fingertips online, for a more modern and convenient customer experience. Pak Dream Rent a Car provides both on-demand and pre-scheduled vehicles for any situation, occasion or event, such as: Corporate Office Staff Tours and Trips Weddings Families / Individuals

</p>
	                					<a href="/about" class="btn btn-outline-primary"><span>More About Us</span><i class="icon-long-arrow-right"></i></a>
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={ab1} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a target='_blank' href="https://www.youtube.com/watch?v=dgOSE4M9-y4" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>






              <div className='container' style={{background :  "#f0f0f0"}}>
<br />
             <p class="text-center">AFFORDABLE CARS IN KARACHI


</p>
             <h2 class="title mb-4 text-center">Why <b>PakDreamRentACar.com?</b> 

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                    {/* <i class="icon-local_car_wash"></i> */}
                                    <img src={ch344} alt="" style={{height   : "40px"}}/>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Tours and Trips

</h3>
                                    <p className="cgg">We offer our services to the Tourists and Group Tours of Offices or Schools.





</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Weddings
</h3>
                                    <p className="cgg" style={{}}>We provide our car rental service on weddings for Groom and other attendees.


</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Families / Individuals

</h3>
                                    <p className="cgg">We offer vehicles like cars and vans to the individuals as well as to the families.



</p>
                                </div>
                            </div>
                        </div>
                      
                    </div>
</div>




<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" }}>
<br />
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                		Yasir Arafat

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ I rented 25 days Toyota corola for my needs to travel within the city Karachi. With family. Excellent service. Driver behavior very good. Best rates.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />



<div className='container' style={{background :  "#f23e3e"}}>
<br />
<h2 class="title mb-4 text-center" style={{color  :"white"}}> <b>We Are Experts In Car Rental Service Company</b> 

</h2>
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>250+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 20+
</h3>
                          <p className="cgg1">Professional Team


</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />




























<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <h2>Pak Dream Rent a Car</h2>

                 <p>Welcome to <em>Pak Dream Rent a Car</em>, your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At <em>Pak Dream Rent a Car</em>, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose <em>Pak Dream Rent a Car</em> for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At <em>Pak Dream Rent a Car</em>, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, <em>Pak Dream Rent a Car</em> provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose <em>Pak Dream Rent a Car</em> for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div>
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;