import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import KIT1 from '../assets/images/KIT.jpg';


import Tourism from '../assets/images/Tourism.svg';
// import slid2 from '../assets/images/slid2.webp';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
// import ab2 from '../assets/images/aboutimage.png';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';
import slider from '../assets/images/vide.gif';


import tf from '../assets/images/34.jpeg';
import tf1 from '../assets/images/32.jpeg';
import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
// import ch344444444 from '../assets/images/KIT3.jpg';
import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Honda-BRV_20230829_200536_0000-1024x767.png';
import Toyo3 from '../assets/images/Saloon-Coaster_20230829_201016_0000-1024x767.png';
import Toyo4 from '../assets/images/Toyota-Hiace_20230829_200852_0000-1024x767.png';
import Toyo51 from '../assets/images/bannerFortuner.jpeg';
import Toyo511 from '../assets/images/center.png';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
// import Carousel2 from './Carosel1';
// import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
// import Carousel5 from './Carosel4';
// import Carousel6 from './Carosel5';
// import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
// import Ico from './iconBox';
// import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";


// import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
// import ch2 from '../assets/images/pd2-724x1024.jpg';
// import ch3 from '../assets/images/pd3-724x1024.jpg';
// import ch4 from '../assets/images/pd4-724x1024.jpg';
// import ch5 from '../assets/images/pd5-724x1024.jpg';




const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")
    const [choosetime , setchoosetime] = useState("")
    const [pickupdate , setpickupdate] = useState("")
    const [dropdate , setdropdate] = useState("")
    const [phone , setphone] = useState("")
    const [car , setcar] = useState("")
    const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://pak-dream-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0],res1);
            setSliderPhoto(res1)
          })
        fetch("https://pak-dream-main-back.vercel.app/offer-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto8(res1)
          })
    
        fetch("https://pak-dream-main-back.vercel.app/all-CarBookPrice",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setCarBookPrice(res3)
        
        })
       

       
        fetch("https://pak-dream-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
      


        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

  
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
    
        fetch("https://pak-dream-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))

    setdis(true)
   })
    
 },[])

 
   
    
    
    
    

const SubmitDataMessage = (e) =>{
  e.preventDefault()
 

if(pickup===""){
  swal("Enter Pickup City")
  return
}
if(drop===""){
  swal("Enter Drop City")
  return
}
if(phone.length !== 11){
  swal("Enter Your 11 Digit Mobile Number")
  return
}

                              fetch("https://pak-dream-main-back.vercel.app/createbooking1",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            pickup ,  
                                            drop ,
                                            choosetime ,
                                            pickupdate , 
                                            dropdate,
                                            phone ,
                                            car ,
                                            email ,
                                            Product_Image_Upload : "A",
                                            // Product_Image_Upload : res2.url,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(res12.Error){
                                            this.setState({displayNone : true})

                                          swal(res12.Error)
                                      }
                                      else{
                                              swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                              this.setState({
                                                // Product_Catagories: "",
                                                // Product_Image_Upload : "",
                                                url : "",
                                          })
                                          this.setState({displayNone : true})

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

                                      // swal("There is An Error")                  
                                      }) 
                                  // }
                                  // else{
                                  //   swal("Successfully Upload your Product!");

                                  //     swal("Upload Again")                  
                                  // }
              // })
              // .catch(err=>swal("There is an Error"))

          }  

    
          const setpickup11 = (e)=>{
            setpickup1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false
              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
                  a = true
                  if(CarBookPrice[i].Price){
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car1").innerHTML ="No Any Package"
                  }
                }
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup21 = (e)=>{
            setpickup2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car2").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup31 = (e)=>{
            setpickup3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car3").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup41 = (e)=>{
            setpickup4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car4").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setdrop11 = (e)=>{
            setdrop1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
          if(pickup1){
            let a = false
            for (let i = 0; i < CarBookPrice.length; i++) {
              if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
                a = true  
                if(CarBookPrice[i].Price> 1){
                    console.log("gdfg",CarBookPrice[i].Price)
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                  console.log("gdfg",CarBookPrice[i].Price)
                  document.getElementById("car1").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

            }
            console.log("gdfg")
          }
          const setdrop21 = (e)=>{
            setdrop2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car2").innerHTML ="No Any Package"
                }
              }
              
            }
            if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
            }
          }
          const setdrop31 = (e)=>{
            setdrop3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car3").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

            }
          }
          const setdrop41 = (e)=>{
            setdrop4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup4){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
                a=  true
                  if(CarBookPrice[i].Price){
                  document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car4").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

            }
          }



          const setCarPick1 = (e)=>{
            if(pickup1 && drop1){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Toyota Corolla", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup1,
                      Drop : drop1
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick2 = (e)=>{
            if(pickup2 && drop2){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "THonda BRV", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup2,
                      Drop : drop2
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick3 = (e)=>{
            if(pickup3 && drop3){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Grand Cabin", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup3,
                      Drop : drop3
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick4 = (e)=>{
            if(pickup4 && drop4){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Saloon Coaster", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup4,
                      Drop : drop4
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
        return (
            
          <div>
          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{maxHeight:  '100%' , overflowX :  "hidden"}}>


















            {/* <main className="main" style={{backgroundColor: '#fafafa'}}> */}
              <div className="intro-section change-height-slider" >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5cols d-none d-lg-block" style={{maxHeight: "500px",
    overflowY: "scroll"}}>
                      <nav className="side-nav">
                        <div className="sidenav-title letter-spacing-normal font-size-normal d-flex justify-content-xl-between align-items-center bg-primary justify-content-center text-truncate">Browse Categories
                          <i className="icon-bars float-right h5 text-white m-0 d-none d-xl-block" />
                        </div>
                        {/* End .sidenav-title   font-size-normal */}
                        <ul className="menu-vertical sf-arrows sf-js-enabled" style={{touchAction: 'pan-y'}}>
                        
                       
                          {MainCatogories.map((res,i)=>{
                   if(res.MainCategories === "One Way Drop"){
                       return (
                      <>
                          <li>
                            <Link to={"/main-categories-car-rental/"+res.MainCategories } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                               <img src={res.photo} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   {res.MainCategories}</Link>
                          </li>
                       </>
                    )
                  }
                  else{
                    return (
                   <>
                       <li>
                         <Link to={"/main-categories/"+res.MainCategories } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                            <img src={res.photo} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   {res.MainCategories}</Link>
                       </li>
                    </>
                 
                 )
                   }
                            
                            
                   
                  
                  })
                

                              }

                          <li>
                            <Link to={"/tourism" } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                               <img src={Tourism} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   Tourism</Link>
                          </li>
                          <li>
                            <Link to={"/Corporate-Equiries" } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                               <img src={Toyo511} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   Corporate Equiries</Link>
                          </li>
                          <li>
                            <Link to={"/Contact" } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                               <img src={ch344} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   Contact Us</Link>
                          </li>
                          <li>
                            <Link to={"/About" } className="text-dark" style={{display : "flex",fontWeight : "600"}}>
                               <img src={Toyo511} alt="" style={{width : "45px", height : "35px",marginRight : "10px",objectFit : "contain",marginTop : "-10px"}}/>   About Us</Link>
                          </li>

                       
                        </ul>
                        {/* End .menu-vertical */}
                      </nav>
                      {/* End .dropdown-menu */}
                    </div>
                    <div className="col-xl-9 col-md-12 col-12 mb-md-0" style={{padding : "0px"}}>
                      <div className="intro-slider-container">
                        {/* <div className="intro-slider owl-carousel owl-theme owl-nav-inside row cols-1" data-toggle="owl" data-owl-options="{
                                          &quot;nav&quot;: false,
                                          &quot;dots&quot;: true,
                                          &quot;autoplay&quot;: false,
                                          &quot;autoplayTimeout&quot;: 10000,
                                          &quot;animateOut&quot;: &quot;fadeOut&quot;
                                      }"> */}










{/* <Slider {...settings}>   */}

<Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>
  
    {/* <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={tf} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div> */}
  
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={slider} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>

  
   {SliderPhoto[0] &&SliderPhoto[0].photo1 !== null ? 
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
     <img src={SliderPhoto[0].photo1} alt="" className='change-slid' style={{height: "485px"}}/>
   </div>
     :""
   }
   {SliderPhoto[0] &&SliderPhoto[0].photo2 !== null ? 
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
     <img src={SliderPhoto[0].photo2} alt="" className='change-slid' style={{height: "485px"}}/>
   </div>
         :""
       }
   {SliderPhoto[0] &&SliderPhoto[0].photo3 !== null? 
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
     <img src={SliderPhoto[0].photo3} alt="" className='change-slid' style={{height: "485px"}}/>
   </div>
     :""
   }
   {/* {SliderPhoto[0] &&SliderPhoto[0].photo4!== null ? 
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
     <img src={SliderPhoto[0].photo4} alt="" className='change-slid' style={{height: "485px"}}/>
   </div>
   :""
 } */}
  </Carousel>
 


                        </div>
                  
                    </div>
                   
                  </div>
                </div>
              </div>




              <div className='container lkiklkklklkl' style={{background :  "#95060D"}}>
          
          <div class="row justify-content-center">
          {MainCatogories.map((res,i)=>{
                   if(res.MainCategories === "One Way Drop"){
                       return (
                      <>
                         <div class="col-lg-3 col-sm-6" style={{width : "30%", background : "white",border: "1px solid #95060D" , borderRadius : "20px" ,margin : "10px 5px"}} onClick={()=>props.history.push("/main-categories-car-rental/"+res.MainCategories )}>
                  <div class="icon-box text-center" style={{padding  :"20px 8px" ,height : "100px",justifyContent  : "center",color : "black", }} >
                          <span class="icon-box-icon" style={{padding  :"0px" , }}>

                          <img src={res.photo} alt="" style={{height   : "40px"}}/>
                    </span>
                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title" style={{fontWeight : "600",fontSize : "15px" , margin : "0px",padding  :  "0px"}}>  {res.MainCategories}

</h3>
                      </div>
                  </div>
              </div>
                       </>
                    )
                  }
                  else{
                    return (
                   <>
                   <div class="col-lg-3 col-sm-6" style={{width : "30%", background : "white",border: "1px solid #95060D" , borderRadius : "20px" ,margin : "10px 5px"}} onClick={()=>props.history.push("/main-categories/"+res.MainCategories )}>
                   <div class="icon-box text-center" style={{padding  :"20px 8px" ,height : "100px",justifyContent  : "center",color : "black", }} >
                           <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                           <img src={res.photo} alt="" style={{height   : "40px"}}/>
                     </span>
                       <div class="icon-box-content" style={{}}>
                           <h3 class="icon-box-title" style={{fontWeight : "600",fontSize : "15px" , margin : "0px",padding  :  "0px"}}>  {res.MainCategories}
 
 </h3>
                       </div>
                   </div>
               </div>   
                  
                    </>
                 
                 )
                   }
                  })
                   }
 <div class="col-lg-3 col-sm-6" style={{width : "30%", background : "white",border: "1px solid #95060D" , borderRadius : "20px" ,margin : "10px 5px"}} onClick={()=>props.history.push("/tourism" )}>
                   <div class="icon-box text-center" style={{padding  :"20px 8px" ,height : "100px",justifyContent  : "center",color : "black", }} >
                           <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                           <img src={Tourism} alt="" style={{height   : "40px"}}/>
                     </span>
                       <div class="icon-box-content" style={{}}>
                           <h3 class="icon-box-title" style={{fontWeight : "600",fontSize : "15px" , margin : "0px",padding  :  "0px"}}>  Tourism
 
 </h3>
                       </div>
                   </div>
               </div>   <div class="col-lg-3 col-sm-6" style={{width : "30%", background : "white",border: "1px solid #95060D" , borderRadius : "20px" ,margin : "10px 5px"}} onClick={()=>props.history.push("/Corporate-Equiries" )}>
                   <div class="icon-box text-center" style={{padding  :"20px 8px" ,height : "100px",justifyContent  : "center",color : "black", }} >
                           <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                           <img src={Toyo511} alt="" style={{height   : "40px"}}/>
                     </span>
                       <div class="icon-box-content" style={{}}>
                           <h3 class="icon-box-title" style={{fontWeight : "600",fontSize : "15px" , margin : "0px",padding  :  "0px"}}>   Corporate Equiries
 
 </h3>
                       </div>
                   </div>
               </div>  


          </div>
</div>

<br />


              <div id="booking" class="section">
		<div class="section-center">
			<div class="container">
				<div class="row">
					<div class="booking-form">
						<form onSubmit={(e)=>SubmitDataMessage(e)}>
					
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Choose PickUp">Choose PickUp</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Choose Drop Off">Choose Drop Off</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

                  </div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Choose Time</span>
										<input class="form-control" type="time" required placeholder="Choose Time"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Email</span>
										<input class="form-control" type="email" required placeholder="Enter Email"  value={email} onChange={(e)=>setemail(e.target.value)}/>
									</div>
								</div>
							
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP DATE</span>
										<input class="form-control" type="date" required  value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF DATE  </span>
										<input class="form-control" type="date" required  value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PHONE NUMBER
</span>
                    <input class="form-control" type="number" required placeholder="Enter PHONE NUMBER"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Car</span>
                    <select class="form-control" required="required" aria-required="true"  value={car} onChange={(e)=>setcar(e.target.value)}>
                      <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>
</select>
										<span class="select-arrow"></span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-btn">
										<button class="submit-btn">Book Now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>



  <div className="container deal-section">
  <h3 className="title text-center font-weight-bold mt-3">  Find Your Best Car Here <br />
                <span style={{color : "#95060D"}}>
                  Pak Dream Rent a Car 
                  </span> 
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

            

<div className='container' style={{background :  "white"}}>
<h3 className="title text-center font-weight-bold mt-3"> Our Transport all over Pakistan <br />
                <span style={{color : "#95060D"}}>
                 One Way Drop Packages
                  </span> 
</h3>
        <p class="mb-2 text-center" style={{color : "black",fontWeight:600}}>
        Our fleet includes all latest models with neat & clean cars like BMW, Mercedes,Honda, Toyota etc. Get with Professional Drivers in Karachi or all over Pakistan.


        </p>
             <p class="title mb-2 text-center" style={{color : "black", fontSize : "15px",fontWeight : "500"}}><b>
( Note : With Driver Only Car Fuel and Tools Included )

</b> 

</p>
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Toyota Corolla


</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup1} onChange={(e)=>setpickup11(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop1} onChange={(e)=>setdrop11(e.target.value)} >
                   <option value="">Choose an option</option>
                     <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car1">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a class="btn btn-round" style={{background : "white",color  : "black"}} onClick={()=>setCarPick1()}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo2} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Honda BRV



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup2} onChange={(e)=>setpickup21(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop2} onChange={(e)=>setdrop21(e.target.value)} >
                   <option value="">Choose an option</option>
                         <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car2">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick2()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo3} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Grand Cabin



</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup3} onChange={(e)=>setpickup31(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop3} onChange={(e)=>setdrop31(e.target.value)} >
                   <option value="">Choose an option</option>
                         <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car3">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick3()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo4} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Saloon Coaster



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup4} onChange={(e)=>setpickup41(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop4} onChange={(e)=>setdrop41(e.target.value)} >
                   <option value="">Choose an option</option>
                         <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car4">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick4()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>

              
          </div>
</div>

<br />

<div className="container deal-section">
            
<h3 className="title text-center font-weight-bold mt-3">  Car Rental Monthly Packages <br />
                <span style={{color : "#95060D"}}>
                Rent A Car Pd Providing Daily , Weekly , Monthly Rental Service
                  </span> 
</h3>
        
             <p class="title mb-2 text-center" style={{color : "black", fontSize : "15px",fontWeight : "500"}}><b>
             ( Note : Without Fuel & Tax and 10 hours duty )

</b> 
</p>
                <div>
            

<Carousel8/>

                       
                </div>  
              </div>

<br/>
<div className="container banner-group-1">
               
               <div className='container' style={{background :  "#f0f0f0"}}>
                 <br/>
            <p class="text-center" style={{color :"black"}}>Services


</p>
            <h2 class="title mb-4 text-center" style={{color :"#95060D"}}> <b>Pakdreamrentacar.com</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",}}>Car Rental Booking
</h3>
                                   <p className="cgg">Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",}}>One Way Booking
</h3>
                                   <p className="cgg" style={{}}>


Book your next adventure with PakDreamRentACar and receive the added benefit of a travel plan. Explore with peace of mind, knowing that you're protected against unforeseen circumstances.
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px",}}> Hotels Booking
</h3>
                                   <p className="cgg">Enjoy the convenience of booking hotels at discounted prices with us. Whether you're planning a business trip or a leisurely getaway, we offer a wide selection of accommodations to suit every budget.



</p>
                               </div>
                           </div>
                       </div>
                       {/* <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Train Booking
</h3>
                                   <p className="cgg">Experience the convenience of train travel at your fingertips. Our platform offers a simple and efficient way to book your train tickets. Enjoy a streamlined booking process, reliable schedules, and excellent customer service. Start your journey with confidence and book now!





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{ontWeight : "600",fontSize : "22px"}}>Flights Booking
</h3>
                                   <p className="cgg" >

                                  
Book a domestic flight through PakDreamRentACar and enjoy the perk of free baggage wrap at Lahore Airport. Safeguard your luggage with our secure and durable wrapping service, ensuring a stress-free travel experience.</p>  

                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Bus Booking 
</h3>
                                   <p className="cgg">

Enjoy convenient and affordable bus tickets with Road Master. Book now to secure your seats and save big on your next trip.




</p>
                               </div>
                           </div>
                       </div> */}
                   </div>
</div>
</div> 
             
  <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3">Today's Best Offer
</h3>
                <div>
            

          
          {/* <div class="row justify-content-center">
              
              <div class="col-lg-6 col-sm-6">
                <a href="tel:+923332221927">

                    <img src={tf1} alt="" className='rgtsrgtsfgs' style={{margin: "auto",
    maxHeight: "600px",
    width: "400px"}}/>
                </a>
              </div>
               
            
              
          </div>
 */}

          <div class="row justify-content-center">
              {SliderPhoto8.map((res,i)=>{
                return(
              <div class="col-lg-6 col-sm-6">
                <a href="tel:+923332221927">

                    <img src={res.photo1} alt="" className='rgtsrgtsfgs' style={{margin: "auto",
    maxHeight: "600px",
    width: "400px"}}/>
                </a>
              </div>
                )

              })

              }
              
            
              
          </div>
                       
                </div>  
              </div>

             
             

            	<div class="video-banner bg-light">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
		                				<h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Pak Dream Rent a Car</strong></h3>
	                					<p>Pak Dream Rent a Car is an on-demand, car-rental marketplace geared to disrupt the traditional renting industry. Pak Dream Rent a Car car-rental services are available at your fingertips online, for a more modern and convenient customer experience. Pak Dream Rent a Car provides both on-demand and pre-scheduled vehicles for any situation, occasion or event, such as: Corporate Office Staff Tours and Trips Weddings Families / Individuals

</p>
	                					<a href="/about" class="btn btn-outline-primary"><span>More About Us</span><i class="icon-long-arrow-right"></i></a>
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={ab1} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a href="https://www.youtube.com/watch?v=dgOSE4M9-y4" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>



              <div className="container banner-group-1">
               
                <div className='container' style={{background :  "#f0f0f0"}}>
                  <br/>
             <p class="text-center">AFFORDABLE CARS IN KARACHI


</p>
             <h2 class="title mb-4 text-center"  style={{color :"#95060D"}}>Why <b>Pakdreamrentacar.com?</b> 

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                    {/* <i class="icon-local_car_wash"></i> */}
                                    <img src={ch344} alt="" style={{height   : "40px"}}/>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Rent a car <br /> with a driver
</h3>
                                    <p className="cgg">Options for with driver available



</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer Online <br /> Support
</h3>
                                    <p className="cgg" style={{}}>Call us Anywhere Anytime
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
</h3>
                                    <p className="cgg">Efficient safety and cleanliness checks

</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch344444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> No security deposit
</h3>
                                    <p className="cgg">No prior booking charges or security deposits



</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch34444444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{ontWeight : "600",fontSize : "22px"}}>Multiple Locations
</h3>
                                    <p className="cgg" >200+ Locations
</p>  

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime <br /> You Want 
</h3>
                                    <p className="cgg">24/7 Online Reservation




</p>
                                </div>
                            </div>
                        </div>
                    </div>
</div>







             



                <div className="container">
                <div className="row">
                  <div className="col-12" style={{padding : "0px"}}>
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch34+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>



<div className='container' style={{background :  "#95060D"}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>250+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                          <p className="cgg1">Quality Support

</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />











<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" , backgroundSize : "unset"}}>
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                		Yasir Arafat

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ I rented 25 days Toyota corola for my needs to travel within the city Karachi. With family. Excellent service. Driver behavior very good. Best rates.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />

                <div className="container icon-boxes-section">
           

                <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>

              <h3 className="title font-weight-bold" style={{  height: "39px",padding  : "0px",margin : "0px" , borderBottom : "1px solid #eb3d32"}}>Cars
</h3>
               <Link to={"/categories/Cars/all-products" }><a  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#95060D", fontWeight  : "600"}} >VIEW ALL</a></Link>
                        
                </div>
                <div>
             



<Carousel4/>

                       
                </div>  
              </div>
           






                <hr className="mt-2 mb-0" />
              </div>

                
              </div>
              <br />



           

           <div className="container banner-group-1">
               
               <div className='container' style={{background :  ""}}>
                 <br/>
            
            <h2 class="title mb-4 text-center"> <b>Our Offices</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                   
                   
                                 <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                               <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                   
                   
                              <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Karachi Customer Center
 
</h3>
       <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/>  <a  style={{fontSize: "16px"}} href="tel: +92 333 2221927"> +92 333 2221927</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                      <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /> <a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a> 
 
</h3>

                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Lahore Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/> <a  style={{fontSize: "16px"}} href="tel:i+92 306 2221927">+92 306 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /><a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3>  <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Islamabad Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/> <a  style={{fontSize: "16px"}} href="tel: +92 333 2221927"> +92 333 2221927</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                          <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /><a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a>
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Multan  Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/> <a  style={{fontSize: "16px"}} href="tel:i+92 306 2221927">+92 306 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /><a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3>    <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Hyderabad  Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}> <i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/><a  style={{fontSize: "16px"}} href="tel: +92 333 2221927"> +92 333 2221927</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /><a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a> 
 
</h3>





                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid rgb(149, 6, 13)",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                             <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="icon-map-marker" style={{textAlign  : "left",padding: "2px 12px",borderRadius:"30px",
    background: "rgb(149, 6, 13)",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Sukkar Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="icon-phone" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "29px"}}/> <a  style={{fontSize: "16px"}} href="tel:i+92 306 2221927">+92 306 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="icon-envelope" style={{padding: "10px",
    color: "rgb(149, 6, 13)",
    fontSize: "32px"}} /><a href="mailto:info@pakdreamrentacar.com" style={{fontSize: "16px"}}>info@pakdreamrentacar.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       </div>
                   

                     
</div>
</div>   




{/* <div class="container">
	                	<h2 class="title text-center mb-3">Our Offices</h2>

	                	<div class="row">
	                		<div class="col-lg-6">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src="https://lh5.googleusercontent.com/p/AF1QipOLllQ2b4ogb1dGzWzlE3lYWfnNmkEy-adJCGlS=w426-h240-k-no" alt="image"/>
	                						</figure>
	                					</div>
	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Pak Dream Rent a Car, pak dream transport, 
</h3>
	                							<address> Gulistan-e-Johar, Karachi, 75400</address>
	                							<div><a href="tel:+923332221927">+92 333 2221927</a></div>
	                							<div><a href="tel:+923062221927">+92 306 2221927</a></div>

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
                								<div>Monday - Saturday 24/7 Hours</div>

                								<a href="https://maps.app.goo.gl/KWJiy9LdY3XFRRpu5" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>

	                		<div class="col-lg-6">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src="https://lh5.googleusercontent.com/p/AF1QipOLllQ2b4ogb1dGzWzlE3lYWfnNmkEy-adJCGlS=w426-h240-k-no" alt="image"/>
	                						</figure>
	                					</div>

	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Other Franchise </h3>
	                							<address><b>Lahore</b> </address>
	                							<address><b>Islamabad</b> </address>
	                							<address><b>Multan</b> </address>
	                							<div><a href="tel:+923062221927">+92 306 2221927</a></div>

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
												<div>Monday - Saturday 24/7 Hours</div>
                								<a href="https://maps.app.goo.gl/KWJiy9LdY3XFRRpu5" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>
	                	</div>
             
</div> */}
             

        

              <div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">
                  <div className="arrivals col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">FEATURED
 
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/new-arrival" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product10.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }



                      {/* End .product */}
                    </div>
                  </div>
                  <div className="recommend col-lg-4 mb-2 mb-xl-0 order-lg-0 order-md-first">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">RECENT
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                      <Link to="/product/recommended-product" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product9.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                  <div className="expert col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">TOP RATED
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/expert-pick" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product11.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                </div>
              </div>
           
        
              <div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">

            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Defence">Rent A Car in Defence</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sindhi-Muslim-Society">Rent A Car in Sindhi Muslim Society</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Malir-Cantt">Rent A Car in Malir Cantt</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Korangi-Industrial-Area">Rent A Car in Korangi Industrial Area</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Bahria-Town-Karachi">Rent A Car in Bahria Town Karachi</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Clifton">Rent A Car in Clifton</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Port-Qasim">Rent A Car in Port Qasim</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Nizamabad">Rent A Car in Nizamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Bufferzone">Rent A Car in Bufferzone</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Tariq-Road">Rent A Car in Tariq Road</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Baldia-Town">Rent A Car in Baldia Town</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-North-Nizamabad">Rent A Car in North-Nizamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulistan-e-Johar">Rent A Car in Gulistan-e-Johar</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Iqbal">Rent A Car in Gulshan-e-Iqbal</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Hadeed">Rent A Car in Gulshan-e-Hadeed</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Maymar">Rent A Car in Gulshan-e-Maymar</Link></p>
                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-karachi">Rent A Car in karachi</Link></p>
                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/car-rental-in-karachi-pakistan">Car Rental in karachi Pakistan</Link></p>
                            
                          
              
            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                           
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Pakistan">Rent A Car in Pakistan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Islamabad">Rent A Car in Islamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Lahore">Rent A Car in Lahore</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Multan">Rent A Car in Multan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Hyderabad">Rent A Car in Hyderabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Peshawar">Rent A Car in Peshawar</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/hotel-in-karachi">hotel in karachi</Link></p>

            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                            
                                    
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/categories/One%20Way%20Drop/all-products">One Way Drop</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-islamabad">One Way Drop karachi to Islamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-faisalabad">One Way Drop karachi to faisalabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-multan">One Way Drop karachi to Multan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-lahore">One Way Drop karachi to lahore</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Airport-Pick-&-Drop">Rent A Car For Airport Pick & Drop</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Wedding-Rent-A-Car">Rent A Car in Wedding Transport</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Landhi-Industrial-Area">Rent A Car in Landhi Industrial Area</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/tourism">Rent A Car For Tourism</Link></p>

            </div>
            </div>
            </div>

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
                 <h2>Pak Dream Rent a Car</h2>
 
                 <p>Welcome to <em>Pak Dream Rent a Car</em>, your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At <em>Pak Dream Rent a Car</em>, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose <em>Pak Dream Rent a Car</em> for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At <em>Pak Dream Rent a Car</em>, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, <em>Pak Dream Rent a Car</em> provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose <em>Pak Dream Rent a Car</em> for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div>



              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
       
        </div>
        )
    
}

export default Home;