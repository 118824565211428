import React, { useEffect , useState } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
// import logo1 from '../assets/images/logo3.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
const SubCategoriesProduct = (props)=> {
  const [Subproduct , setSubproduct] = useState([])

  //  history = useHistory();
  useEffect(() =>{

    window.scrollTo(0, 0)

  setSubproduct(JSON.parse(localStorage.getItem("Fav")) ? JSON.parse(localStorage.getItem("Fav")) : [])

},[])



        return (
            <>
          <div className="page-wrapper">
        
        <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">Wishlist<span></span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><a href="#">Shop</a></li>
                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
          <div className="page-content">
            <div className="container">
              <table className="table table-wishlist table-mobile">
                <thead>
                  <tr>
                    <th>Services</th>
                    <th>Price</th>
                    <th>Stock Status</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                {Subproduct.length > 0 ? Subproduct.map((res,i)=>{
                if(res.Course_Sub_Catagories !== ""){
                  return(
                  <tr>
                    <td className="product-col">
                      <div className="product">
                        <figure className="product-media">
                          <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image" />
                          </Link>
                        </figure>
                        <h3 className="product-title">
                          <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                        </h3>{/* End .product-title */}
                      </div>{/* End .product */}
                    </td>
                    <td className="price-col"> Rs. {res.Product_Price_Discounted ? 
                    
                        res.Product_Price_Discounted
                      :
                            "Rs." (res.Product_Price).toFixed(0)
            }</td>
            {res.Product_Stock_Qty > 0 ?
            <>

 <td className="stock-col"><span className="in-stock">In stock</span></td>
 <td className="action-col">
   <button className="btn btn-block btn-outline-primary-2"  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}><i className="icon-cart-plus" />Add to Cart</button>
 </td>
            </>
            :
            <>
             <td className="stock-col"><span className="out-of-stock">Out of stock</span></td>
             <td className="action-col">
               <button className="btn btn-block btn-outline-primary-2 disabled">Out of Stock</button>
             </td>
            </>

            }
                  
                    <td className="remove-col"><button className="btn-remove"><i className="icon-close" /></button></td>
                  </tr>
                  )
                  }
                  if(res.Product_Sub_Catagories !== ""){
                    console.log("323")
                    return(

                    <tr>
                    <td className="product-col">
                      <div className="product">
                        <figure className="product-media">
                          <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image" />
                          </Link>
                        </figure>
                        <h3 className="product-title">
                          <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                        </h3>{/* End .product-title */}
                      </div>{/* End .product */}
                    </td>
                    <td className="price-col"> Rs. {res.Product_Price_Discounted ? 
                    
                        res.Product_Price_Discounted
                      :
                             "Rs. " (res.Product_Price).toFixed(0)
            }</td>
                    {res.Product_Stock_Qty > 0 ?
            <>

 <td className="stock-col"><span className="in-stock">In stock</span></td>
 <td className="action-col">
   <button className="btn btn-block btn-outline-primary-2" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}><i className="icon-cart-plus" />Add to Cart</button>
 </td>
            </>
            :
            <>
             <td className="stock-col"><span className="out-of-stock">Out of stock</span></td>
             <td className="action-col">
               <button className="btn btn-block btn-outline-primary-2 disabled">Out of Stock</button>
             </td>
            </>

            }
                    <td className="remove-col"><button className="btn-remove"><i className="icon-close" /></button></td>
                  </tr>


          )
          }
          else{
            return(

          <tr>
          <td className="product-col">
            <div className="product">
              <figure className="product-media">
                <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >
                  <img src={res.Product_Image_Upload} alt="Product image" />
                </Link>
              </figure>
              <h3 className="product-title">
                <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >{res.Product_Name}</Link>
              </h3>{/* End .product-title */}
            </div>{/* End .product */}
          </td>
          <td className="price-col">Rs.
            
          {res.Product_Price_Discounted ? 
                    
                        res.Product_Price_Discounted
                      :
                          "Rs. "  (res.Product_Price).toFixed(0)
            }
            {/* $84.00 */}
          
          </td>
          {res.Product_Stock_Qty > 0 ?
            <>

 <td className="stock-col"><span className="in-stock">In stock</span></td>
 <td className="action-col">
   <button className="btn btn-block btn-outline-primary-2"  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} ><i className="icon-cart-plus" />Add to Cart</button>
 </td>
            </>
            :
            <>
             <td className="stock-col"><span className="out-of-stock">Out of stock</span></td>
             <td className="action-col">
               <button className="btn btn-block btn-outline-primary-2 disabled">Out of Stock</button>
             </td>
            </>

            }
          <td className="remove-col"><button className="btn-remove"><i className="icon-close" /></button></td>
          </tr>

          )
          }
          })
          : <center style={{width : "100%"}}>
            <br />
            <br />
            <h4>No Items In Wishlist </h4>
          </center>  
          }

                  
                </tbody>
              </table>{/* End .table table-wishlist */}
              {/* <div className="wishlist-share">
                <div className="social-icons social-icons-sm mb-2">
                  <label className="social-label">Share on:</label>
                  <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f" /></a>
                  <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter" /></a>
                  <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram" /></a>
                  <a href="#" className="social-icon" title="Youtube" target="_blank"><i className="icon-youtube" /></a>
                  <a href="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest" /></a>
                </div>
              </div> */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}
      </div>
            </>
        )
    }


export default SubCategoriesProduct;