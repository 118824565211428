import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Honda-BRV_20230829_200536_0000-1024x767.png';
import Toyo3 from '../assets/images/Saloon-Coaster_20230829_201016_0000-1024x767.png';
import Toyo4 from '../assets/images/Toyota-Hiace_20230829_200852_0000-1024x767.png';
// import Toyo51 from '../assets/images/bannerFortuner.jpeg';
// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';



const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [couponShow , setcouponShow] = useState(true)


    const [CarBookPrice , setCarBookPrice] = useState([])

    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")


    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-main-back.vercel.app/all-CarBookPrice",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setCarBookPrice(res3)
        
        })
       
        fetch("https://pak-dream-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
        fetch("https://pak-dream-main-back.vercel.app/AllProduct",{
          method: "GET",
          headers :  {
          "Content-Type" : "application/json" , 
      } ,
      })
      .then(res7=>res7.json())
      .then(res8=>{
          setallproduct(res8)
          let unique_values = res3
          .map((item) => item.CategoriesName)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
          console.log(unique_values)
          let h = []
          unique_values.map((res5,y)=>{
            if(res5 === Categoriesname.categoriesName){
              h.push(res5)
            }
          })
          console.log(h)
          let pro = []
          //  unique_values.map((res9,i)=>{
          res8.map((res19,ii)=>{
              // console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
              if(res19.status && res19.Product_Sub_Catagories  === h[0] ){
                // console.log(res19,ii)
                pro.push(res19)
              }
          // })
          })

          setSubproduct(pro)
          // console.log(NonActive)
      })
      })
        fetch("https://pak-dream-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://pak-dream-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://pak-dream-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://pak-dream-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://pak-dream-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto(res1)
        
        })


        
                    fetch("https://pak-dream-main-back.vercel.app/TagsSort1111",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res9=>res9.json())
                .then(res8=>{
                    const dta = res8.filter((res7,i)=>{
                        if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                            console.log(res7);
                            return res7
                        }
                    })
                    // console.log(dta);
                    setSortedData(dta)
                })
                fetch("https://pak-dream-main-back.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                console.log(res3);
                // setMainCatogories(res3)
                
    
                let dat = []
                res3.map((res55,c)=>{
                    dat.push(res55)
                })
                // console.log(SubClassCategories,asse)
                console.log(dat)
                let data = dat.sort((a, b) => {
                    return a.sort - b.sort;
                });
                setMainCatogories(data)
                
              })
                    fetch("https://pak-dream-main-back.vercel.app/AllCategories",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res4=>res4.json())
                .then(res5=>{
                    let data = res5.filter((res6,i)=>{
                        if (res6.MainCategories === Categoriesname.categoriesName) return res6
                    })
                    setsubCate(data)
                    // console.log(res5,data);
                })

                fetch("https://pak-dream-main-back.vercel.app/CourseAllSubCategories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  let data = res3.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  // console.log(data);
                  setSubClassCategories(data)
              })

            

        

      




},[])


const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
// useEffect(() => {
  
//     return () => {
//         clearInterval()
//     }
//   }, [])



const SortData1 = (a ,b) =>{
            setFal1(false)

          const Pro6 = Product.sort((a, b)=> {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
            });
            setProduct1(Pro6)
            setFal(true)
            console.log(Product1);



            
}
const SortData2 = (a ,b) =>{
    setFal(false)
          const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            setProduct2(Pro6)
            setFal1(true)
            console.log(Product2);



            
}


const ViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.setAttribute("class" , "hover-at-view")
}

const NoViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.removeAttribute("class")
}

// const setSortPrice = (e)=>{
//     SortedName.push(e)
//     console.log(e);
//     if (e === "Low"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//                 return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
//             });
//             setSubproduct(Pro6)

//     }
//     if (e === "A"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//             var nameA = a.Product_Name.toUpperCase(); 
//             var nameB = b.Product_Name.toUpperCase(); 
//             if (nameA < nameB) {
//                 return -1;
//             }
//             if (nameA > nameB) {
//                 return 1;
//             }

//             return 0;
//             });
//             console.log(Pro6);
//             setSubproduct(Pro6)

//     }
//     if (fal)
//     setFal(true)
//     else
//     setFal(false)
// }




const sortedAlgo = (e)=>{
    let d = false
    let da = 0
    SortedName.map((res9,a)=>{
        if(res9 === e){
            d = true
            da = a
        }
    })
    if(d){
        SortedName.splice(da,1)
    }
    else{
        SortedName.push(e)
    }
    fetch("https://pak-dream-main-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , CategoriesName.categoriesName  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                        if(res9.status && res9.Product_Catagories === CategoriesName.categoriesName){
                            return res9 
                        }
                    })
                
    if(SortedName.length > 0){
        let Coa = []
        SortedName.map((res22,x)=>{
      console.log(res22,SortedName)
//    console.log(Subproduct)
   const Sort = NonActive.filter((res,i)=>{
        res.Product_Name.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        res.Product_Title.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        
            res.tags.map((res1,i)=>{
                if (res1 === res22){
                    // console.log("true")
                    Coa.push(res)
                }
            })
        // }
   })  
    })
     let uniq = [...new Set(Coa)];

   console.log(uniq);
   setSubproduct(uniq)
}
else{
        setSubproduct(NonActive)
    }
   
  })
}














// const addtocartproduct = (...data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


       




  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 if (data111[0].Product_Price_Discounted === null){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else if (data111[0].Product_Price_Discounted){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price_Discounted *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price_Discounted *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else{

//                   }
                  
//                   }
                  











const setpickup11 = (e)=>{
    setpickup1(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop1){
      let a = false
      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
          a = true
          if(CarBookPrice[i].Price){
            document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car1").innerHTML ="No Any Package"
          }
        }
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup21 = (e)=>{
    setpickup2(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop2){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car2").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup31 = (e)=>{
    setpickup3(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop3){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car3").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup41 = (e)=>{
    setpickup4(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop1){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car4").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setdrop11 = (e)=>{
    setdrop1(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
  if(pickup1){
    let a = false
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
        a = true  
        if(CarBookPrice[i].Price> 1){
            console.log("gdfg",CarBookPrice[i].Price)
            document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
          console.log("gdfg",CarBookPrice[i].Price)
          document.getElementById("car1").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
    console.log("gdfg")
  }
  const setdrop21 = (e)=>{
    setdrop2(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup2){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
          a=  true

          if(CarBookPrice[i].Price){
          document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car2").innerHTML ="No Any Package"
        }
      }
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }
  }
  const setdrop31 = (e)=>{
    setdrop3(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup3){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
          a=  true

          if(CarBookPrice[i].Price){
          document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car3").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
  }
  const setdrop41 = (e)=>{
    setdrop4(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup4){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
        a=  true
          if(CarBookPrice[i].Price){
          document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car4").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
  }



  const setCarPick1 = (e)=>{
    if(pickup1 && drop1){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Toyota Corolla", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup1,
              Drop : drop1
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick2 = (e)=>{
    if(pickup2 && drop2){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "THonda BRV", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup2,
              Drop : drop2
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick3 = (e)=>{
    if(pickup3 && drop3){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Grand Cabin", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup3,
              Drop : drop3
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick4 = (e)=>{
    if(pickup4 && drop4){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Saloon Coaster", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup4,
              Drop : drop4
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
        return (









<>
          <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">{CategoriesName.categoriesName}<span>Car Rental</span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">Car Rental</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">Highly Recommend</li> */}
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
         
             

             

<div className='container' style={{background :  "white"}}>
<p class="text-center">Our Transport all over Pakistan


</p>
<h1 class="title mb-2 text-center"><b> One Way Drop Packages</b> 

</h1>
             <p class="title mb-2 text-center" style={{color : "black", fontSize : "15px",fontWeight : "500"}}><b> Pak Dream Rent A Car is one of
the Best leading Car rental services in Pakistan.
Since starting our service of rent a car in Karachi back in the year 2015, we've enabled people to travel in a high-quality vehicle safely and accessibly. We've expanded our operations considerably in the past 9+ years and offer car renting services in many locations all over Pakistan. Choose a car from our wide range of vehicles on your next trip. Just let us know when and where!
<br />
( Note : Car Fuel and Tools Included  Without Tax)

</b> 

</p>
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Toyota Corolla


</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup1} onChange={(e)=>setpickup11(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop1} onChange={(e)=>setdrop11(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car1">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a class="btn btn-round" style={{background : "white",color  : "black"}} onClick={()=>setCarPick1()}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo2} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Honda BRV



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup2} onChange={(e)=>setpickup21(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop2} onChange={(e)=>setdrop21(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car2">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick2()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo3} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Grand Cabin



</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup3} onChange={(e)=>setpickup31(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop3} onChange={(e)=>setdrop31(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car3">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick3()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo4} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Saloon Coaster



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup4} onChange={(e)=>setpickup41(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop4} onChange={(e)=>setdrop41(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car4">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick4()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>

              
          </div>
</div>

<br />
<br />









<div className='container' style={{background :  "#f0f0f0"}}>
             <p class="text-center">OUR SERVICES



</p>
             <h2 class="title mb-4 text-center"> <b>What We Offer.
</b> 

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                    {/* <i class="icon-local_car_wash"></i> */}
                                    <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Trusted Rent

</h3>
                                    <p className="cgg">We have been providing a car rental service for the past decade with a trusted process.





</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch344} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Variety Of Cars

</h3>
                                    <p className="cgg" style={{}}>We have a variety of cars for renting, from luxury to affordable car


</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch344444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Budget
</h3>
                                    <p className="cgg">They are light on your pocket and have a commendable engine capacity.



</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
</div>
















        </main>{/* End .main */}


</>

        )
    }



export default SubCategoriesProduct;