import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import logo1 from '../assets/images/Capture.PNG';

class Thankyou extends Component {
    render() {
        return (
            <div>
                
                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <span className="icon-check_circle display-3 text-success"></span>
                                <h2 className="display-3 text-black">Thank you!</h2>
                                <p className="lead">Thank you for visiting pakdreamrentacar.com. <br /> You will received an email message shortly.</p>
                               <center>
                                <img src={logo1} alt="" />
                                </center> 
                                <p className="lead mb-2">Your Booking was completed Successfully</p>
                                {/* <p className="lead mb-2">It will be delivered in 3 business days.</p> */}
                                <p className="lead mb-2"> <EmailIcon/> An email receipt including the details of your Booking has been sent <br /> to the email address provided. Please keep it for your records.</p>
                                <p><Link to='/'><div className="btn btn-sm btn-primary">Back to Home</div></Link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Thankyou;